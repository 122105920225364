export const comments = {
  "ja": {
    "score": {
      "up": "生活習慣スコアは12月より%d点上がりました。2024年良いスタートがきれていますね。<br>\n2月のテーマは、<b>❝厳しい冬を乗り切ろう！❞</b>​   です。寒さがまだまだ厳しいこの季節。インフルエンザやノロウイルスといった感染症が流行しやすい時期ともいわれています。＆wellを活用して、感染症の予防・対策に欠かせないポイントをおさえよう！\n\n",
      "down": "生活習慣スコアは12月より%d点下がりました。<br>\n2月のテーマは、<b>❝厳しい冬を乗り切ろう！❞</b>​   です。寒さがまだまだ厳しいこの季節。インフルエンザやノロウイルスといった感染症が流行しやすい時期ともいわれています。＆wellを活用して、感染症の予防・対策に欠かせないポイントをおさえよう！\n\n",
      "equal100": "生活習慣スコアは12月に引き続き%満点%です。大変素晴らしいです！<br>\n2月のテーマは、<b>❝厳しい冬を乗り切ろう！❞</b>​   です。寒さがまだまだ厳しいこの季節。インフルエンザやノロウイルスといった感染症が流行しやすい時期ともいわれています。＆wellを活用して、感染症の予防・対策に欠かせないポイントをおさえよう！\n\n",
      "equal": "生活習慣スコアは12月と同じ点数です。<br>\n2月のテーマは、<b>❝厳しい冬を乗り切ろう！❞</b>​   です。寒さがまだまだ厳しいこの季節。インフルエンザやノロウイルスといった感染症が流行しやすい時期ともいわれています。＆wellを活用して、感染症の予防・対策に欠かせないポイントをおさえよう！\n\n"
    },
    "steps": {
      "up": "歩数スコアは12月より%d点上がりました。その調子です！<br>\n歩く時は３つのポイントを意識しましょう。\r\n<b><br>①姿勢②大股歩き③早歩き</b>\n\n",
      "down": "歩数スコアは12月より%d点下がりました。<br>\n歩く時は３つのポイントを意識しましょう。\r\n<b><br>①姿勢②大股歩き③早歩き</b>\n\n",
      "equal": "歩数スコアは12月と同じ点数です。<br>\n歩く時は３つのポイントを意識しましょう。\r\n<b><br>①姿勢②大股歩き③早歩き</b>\n\n",
    },
    "exercise": {
      "up": "運動のスコアは12月より%d点上がりました。身体を動かす機会が増えていますね。<br>\n筋トレには筋力アップ以外にも睡眠の質を高めたり、美肌効果が期待できます。<a href=\"https://go.andwell.jp/library/bcf6e321-b41a-11ee-a2ac-0a74c02d2ee3\">＜こちらのコラム＞</a>で６つのメリットをご紹介！\n\n",
      "down": "運動のスコアは12月より%d点下がりました。<br>\n外に出るのが億劫な季節ですね。<a href=\"https://go.andwell.jp/library/23e9fd5e-8527-11ee-92e0-06e9236fa737\">＜こちらのコラム＞</a>では、室内でできる簡単なストレッチをご紹介！\n\n",
      "equal": "運動のスコアは12月と同じ点数です。<br>\n外に出るのが億劫な季節ですね。<a href=\"https://go.andwell.jp/library/23e9fd5e-8527-11ee-92e0-06e9236fa737\">＜こちらのコラム＞</a>では、室内でできる簡単なストレッチをご紹介！\n\n"
    },
    "meal": {
      "up": "食事のスコアは12月より%d点上がりました。<br>\n食事の時は、<b>一口30回以上</b>噛むことが理想です。あなたは何回噛んでいますか？少ない人は、まずは今より5回増やしてみましょう。\n\n",
      "down": "食事のスコアは12月より%d点下がりました。<br>\n食事の時は、<b>一口30回以上</b>噛むことが理想です。あなたは何回噛んでいますか？少ない人は、まずは今より5回増やしてみましょう。\n\n",
      "equal": "食事のスコアは12月と同じ点数です。<br>\n食事の時は、<b>一口30回以上</b>噛むことが理想です。あなたは何回噛んでいますか？少ない人は、まずは今より5回増やしてみましょう。\n\n"
    },
    "drinking": {
      "up": "飲酒のスコアは12月より%d点上がりました。<br>\n新年会などでお酒を飲む機会が多い時期にも関わらず、飲み過ぎた日が少なくなっていますね。この調子です！\n\n",
      "down": "飲酒のスコアは12月より%d点下がりました。<br>\nアルコールには利尿作用があり、お酒を飲むと脱水症状になりやすいです。こまめに水を飲むことを心がけて。\n\n",
      "equal": "飲酒のスコアは12月と同じ点数です。<br>\nアルコールには利尿作用があり、お酒を飲むと脱水症状になりやすいです。こまめに水を飲むことを心がけて。\n\n"
    },
    "sleep": {
      "up": "睡眠のスコアは12月より%d点上がりました。<br>\n体内時計の調整のカギは、<b>光・食事・運動！</b><a href=\"https://go.andwell.jp/library/565b5dde-a075-11ee-a6a3-06e9236fa737\">＜こちらの動画＞</a>では、体内時計の調整のために気をつけることを伝授！\n\n",
      "down": "睡眠のスコアは12月より%d点下がりました。<br>\n体内時計の調整のカギは、<b>光・食事・運動！</b><a href=\"https://go.andwell.jp/library/565b5dde-a075-11ee-a6a3-06e9236fa737\">＜こちらの動画＞</a>では、体内時計の調整のために気をつけることを伝授！\n\n",
      "equal": "睡眠のスコアは12月と同じ点数です。<br>\n体内時計の調整のカギは、<b>光・食事・運動！</b><a href=\"https://go.andwell.jp/library/565b5dde-a075-11ee-a6a3-06e9236fa737\">＜こちらの動画＞</a>では、体内時計の調整のために気をつけることを伝授！\n\n",
    },
    "stress": {
      "up": "ストレスのスコアは12月より%d点上がりました。<br>\n<b>”笑う”</b>ことで、心身ともにリラックス効果が期待できます。口角を上げて、声を出して笑いましょう！\n\n",
      "down": "ストレスのスコアは12月より%d点下がりました。<br>\n<b>”笑う”</b>ことで、心身ともにリラックス効果が期待できます。口角を上げて、声を出して笑いましょう！\n\n",
      "equal": "ストレスのスコアは12月と同じ点数です。<br>\n<b>”笑う”</b>ことで、心身ともにリラックス効果が期待できます。口角を上げて、声を出して笑いましょう！\n\n",
    }
  },
  "en": {
    "score": {
      "up": "Your Lifestyle Score has gone up by %d points since December. You are off to a good start in 2024.<br>\nThe theme for February is <b>\"Surviving the harsh winter! \"</b>. This is the season when the cold weather is still severe. It is also said to be a time when infectious diseases such as influenza and norovirus are more likely to spread. Let's take advantage of &well to learn the essential tips for preventing infections and taking measures against them!\n\n",
      "down": "Your Lifestyle Score has dropped by %d points since December.<br>\nThe theme for February is <b>\"Surviving the harsh winter! \"</b>. This is the season when the cold weather is still severe. It is also said to be a time when infectious diseases such as influenza and norovirus are more likely to spread. Let's take advantage of &well to learn the essential tips for preventing infections and taking measures against them!\n\n",
      "equal100": "Your Lifestyle score is perfect, just as it was in December. <br>\nThe theme for February is <b>\"Surviving the harsh winter! \"</b>. This is the season when the cold weather is still severe. It is also said to be a time when infectious diseases such as influenza and norovirus are more likely to spread. Let's take advantage of &well to learn the essential tips for preventing infections and taking measures against them!\n\n",
      "equal": "Your Lifestyle score is the same as it was in December.<br>\nThe theme for February is <b>\"Surviving the harsh winter! \"</b>. This is the season when the cold weather is still severe. It is also said to be a time when infectious diseases such as influenza and norovirus are more likely to spread. Let's take advantage of &well to learn the essential tips for preventing infections and taking measures against them!\n\n"
    },
    "steps": {
      "up": "Your steps score has gone up %d pts since December.Keep it up!<br>\nKeep these three things in mind when walking: \r\n<b><br>(1) Good posture (2) Taking big steps (3) Walking fast</b>\n\n",
      "down": "Your steps score has gone down %d pts since December. <br>\nKeep these three things in mind when walking: \r\n<b><br>(1) Good posture (2) Taking big steps (3) Walking fast</b>\n\n",
      "equal": "Your steps score is the same as December. <br>\nKeep these three things in mind when walking: \r\n<b><br>(1) Good posture (2) Taking big steps (3) Walking fast</b>\n\n"
    },
    "exercise": {
      "up": "Your exercise score has gone up %d pts since December.You have more opportunities for physical exercise.<br>\nIn addition to increasing muscle strength, muscle training can also improve the quality of sleep and skin tone. Read about the six benefits in <a href=\"https://go.andwell.jp/library/bcf6e321-b41a-11ee-a2ac-0a74c02d2ee3\">this column</a>!\n\n",
      "down": "Your exercise score has gone down %d pts since December.<br>\nIt's that time of year when it's hard to get out of the house. In <a href=\"https://go.andwell.jp/library/23e9fd5e-8527-11ee-92e0-06e9236fa737\">this column</a>, we introduce some simple stretches that you can do indoors!\n\n",
      "equal": "Your exercise score is the same as December.<br>\nIt's that time of year when it's hard to get out of the house. In <a href=\"https://go.andwell.jp/library/23e9fd5e-8527-11ee-92e0-06e9236fa737\">this column</a>, we introduce some simple stretches that you can do indoors!\n\n"
    },
    "meal": {
      "up": "Your food score has gone up %d pts since December.<br>\nIdeally, you <b>should chew at least 30 times per bite</b> when you eat. How many times do you chew? If you don't chew enough, start by chewing 5 more times per bite.\n\n",
      "down": "Your food score has gone down %d pts since December.<br>\nIdeally, you <b>should chew at least 30 times per bite</b> when you eat. How many times do you chew? If you don't chew enough, start by chewing 5 more times per bite.\n\n",
      "equal": "Your food score is the same as December.<br>\nIdeally, you <b>should chew at least 30 times per bite</b> when you eat. How many times do you chew? If you don't chew enough, start by chewing 5 more times per bite.\n\n"
    },
    "drinking": {
      "up": "Your alcohol score has gone up %d pts since December.<br>\nEven though it is the time of year when there are many opportunities to drink alcohol at New Year's parties and other occasions, you are having fewer days where you drink too much. Keep it up!\n\n",
      "down": "Your alcohol score has gone down %d pts since December.<br>\nAlcohol has diuretic properties and drinking alcohol can easily dehydrate you. Be sure to drink water frequently.\n\n",
      "equal": "Your alcohol score is the same as December.<br>\nAlcohol has diuretic properties and drinking alcohol can easily dehydrate you. Be sure to drink water frequently.\n\n"
    },
    "sleep": {
      "up": "Your sleep score has gone up %d pts since December.<br>\n<b>Light, diet, and exercise</b> are the keys to regulating the body clock! In <a href=\"https://go.andwell.jp/library/565b5dde-a075-11ee-a6a3-06e9236fa737\">this video</a>, we teach you what you need to pay attention to in order to regulate your body clock!\n\n",
      "down": "Your sleep score has gone down %d pts since December.<br>\n<b>Light, diet, and exercise</b> are the keys to regulating the body clock! In <a href=\"https://go.andwell.jp/library/565b5dde-a075-11ee-a6a3-06e9236fa737\">this video</a>, we teach you what you need to pay attention to in order to regulate your body clock!\n\n",
      "equal": "Your sleep score is the same as December.<br>\n<b>Light, diet, and exercise</b> are the keys to regulating the body clock! In <a href=\"https://go.andwell.jp/library/565b5dde-a075-11ee-a6a3-06e9236fa737\">this video</a>, we teach you what you need to pay attention to in order to regulate your body clock!\n\n"
    },
    "stress": {
      "up": "Your stress score has gone up %d pts since December.<br>\n<b>Laughing</b> can help relax both the body and mind.  Open your mouth, raise your voice, and laugh!\n\n",
      "down": "Your stress score has gone down %d pts since December.<br>\n<b>Laughing</b> can help relax both the body and mind.  Open your mouth, raise your voice, and laugh!\n\n",
      "equal": "Your stress score is the same as December.<br>\n<b>Laughing</b> can help relax both the body and mind.  Open your mouth, raise your voice, and laugh!\n\n"
    }
  }
}